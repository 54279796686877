import { useState, useEffect } from 'react';
import { Input, Select, DatePicker, Form, Radio } from 'antd';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import usePatientContext from '../PatientContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;

/**
 * useFields is a custom hook that provides an array of field patients for patient data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const usePatientFields = (purpose) => {
  const { enums } = usePatientContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [socialSecurityNumbers, setSocialSecurityNumbers] = useState([]);

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const getPatients = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/patients' });

      const socialSecurityNumberValues = data.map((item) => {
        if (item._id !== id) {
          return item.social_security_number;
        }
        return [];
      });

      setSocialSecurityNumbers(socialSecurityNumberValues);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const validateSocialSecurityNumber = (_, value) => {
    const isSocialSecurityNumber = socialSecurityNumbers?.some(
      (s) => s === value
    );
    if (isSocialSecurityNumber) {
      return Promise.reject(
        new Error(t(`patients.form.error.SocialSecurityNumberExist`))
      );
    }
    return Promise.resolve();
  };

  useEffect(async () => {
    await getPatients();
  }, []);

  return [
    {
      name: ['code'],
      input:
        purpose === 'create' ? (
          <p>{t(`patients.form.text.code`)}</p>
        ) : (
          <Input disabled />
        )
    },
    {
      name: ['created_at'],
      input:
        purpose === 'create' ? (
          <p>{dayjs().format('DD/MM/YYYY')}</p>
        ) : (
          <DatePicker format="DD/MM/YYYY" disabled />
        )
    },
    {
      name: ['latitude_number_fake']
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['usage_name']
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['social_security_number'],
      rules: [
        {
          validator: validateSocialSecurityNumber
        },
        { required: true },
        { len: 15 }
      ]
    },
    {
      name: ['date_of_birth'],
      rules: [{ required: true }],
      input: <DatePicker format="DD/MM/YYYY" />
    },
    {
      name: ['rank_birth'],
      input: <Input type="number" />
    },
    {
      name: ['quality'],
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(enums?.qualities || []).map((q) => (
            <Option key={q} value={q}>
              {t(`enums.${q}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'phone_number.number',
      name: ['phone_number'],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select>
              <Select.Option value="+33">+33</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['email']
    },
    {
      label: 'address.number',
      name: ['address', 'number']
    },
    {
      label: 'address.street',
      name: ['address', 'street']
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code']
    },
    {
      label: 'address.city',
      name: ['address', 'city']
    },
    {
      name: ['medical_device_reference'],
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(enums.references || []).map((r) => (
            <Option key={r} value={r}>
              {t(`enums.${r}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['medical_device_serial_number']
    },
    {
      name: ['communicator_model'],
      input: (
        <Radio.Group
          options={(enums?.communicators || []).map((com) => t(`enums.${com}`))}
        />
      )
    },
    {
      name: ['serial_number_communicator']
    }
  ];
};

export default usePatientFields;
