import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { folderStatuses } from '../../utils/constants/tagColors';
import usePatientContext from './PatientContext';
import { render } from 'react-dom';

export const useColumns = () => {
  const { t } = useTranslation();
  const { enums } = usePatientContext();

  return [
    {
      title: t('patients.columns.code'),
      key: 'code',
      dataIndex: 'code',
      sorter: true
    },
    {
      title: t('patients.columns.invoice_folder_status'),
      key: 'invoice_folder_status',
      dataIndex: ['invoice_folder_status'],
      render: (invoice_folder_status) =>
        invoice_folder_status ? (
          <Tag color={folderStatuses[invoice_folder_status]}>
            {t(`patients.tags.${invoice_folder_status}`)}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: false,
      filters: enums?.folderStatuses?.map((f) => ({
        text: t(`patients.tags.${f}`),
        value: f
      }))
    },
    {
      title: t('patients.columns.folder_status'),
      key: 'folder_status',
      dataIndex: ['folder_status'],
      render: (folder_status) =>
        folder_status ? (
          <Tag color={folderStatuses[folder_status]}>
            {t(`patients.tags.${folder_status}`)}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: false,
      filters: enums?.folderStatuses?.map((f) => ({
        text: t(`patients.tags.${f}`),
        value: f
      }))
    },
    {
      title: t('patients.columns.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('patients.columns.usage_name'),
      key: 'usage_name',
      dataIndex: 'usage_name',
      sorter: true,
      render: (usage_name) => usage_name || '-'
    },
    {
      title: t('patients.columns.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('patients.columns.social_security_number'),
      key: 'social_security_number',
      dataIndex: 'social_security_number',
      sorter: true
    },
    {
      title: t('patients.columns.center_name'),
      key: 'center',
      dataIndex: 'center',
      render: (center) => center?.center_name || '-'
    },
    {
      title: t('patients.columns.soldto'),
      key: 'center',
      dataIndex: 'center',
      render: (center) => center?.soldto || '-'
    },
    {
      title: t('patients.columns.medical_device_serial_number'),
      key: 'medical_device_serial_number',
      dataIndex: 'medical_device_serial_number',
      sorter: true
    },
    {
      title: t('patients.columns.city_center'),
      key: 'center',
      dataIndex: 'center',
      render: (center) => (center?.address?.city ? center.address.city : '-'),
      sorter: true
    }
  ];
};
