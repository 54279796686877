import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import dayjs from 'dayjs';
import { Form } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import usePatientContext from './PatientContext';
import { useGenerateFormItem } from '../../utils/generateFormItem/generateFormItem';
import usePatientFields from './fields/patientFields';
import useCaregiverFields from './fields/caregiverFields';
import useCenterFields from './fields/centerFields';
import useInvoicingFields from './fields/invoicingFields';
import useRacFields from './fields/racFields';
import CenterPrecriberDrawer from './CenterPrescriberDrawer';

/**
 * CreateUpdatePatient is a React component that provides a form for creating or updating patient data.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} props.purpose - The purpose of the form, either 'create' or 'edit'.
 * @returns {React.ReactNode} The rendered JSX for the CreateUpdatePatient component.
 */

export const CreateUpdatePatient = ({ purpose }) => {
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    enums,
    setIsSwitchRac,
    setIsSwitchAMC,
    setInitialPrescription,
    setHealthInsurance,
    initialPrescription,
    healthInsurance,
    setSelectedCenter,
    setFormValues,
    setLinkedPrescribers,
    forceRefresh,
    isDrawerOpen,
    model,
    closeDrawer,
    setIsDrawerOpen,
    formValues,
    getPatientById,
    fetchHealthInsuranceIds
  } = usePatientContext();
  const patientFields = usePatientFields(purpose);
  const caregiverFields = useCaregiverFields();
  const [form] = Form.useForm();
  const centerFields = useCenterFields(purpose, id);
  const invoicingFields = useInvoicingFields(purpose, id, form);
  const racFields = useRacFields();
  const [activeKey, setActiveKey] = useState('1');
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const navigate = useNavigate();

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        switch_third_part_rac_date: !data?.switch_third_part_rac
          ? undefined
          : data?.switch_third_part_rac_date,
        switch_third_part_rac_reason: !data?.switch_third_part_rac
          ? undefined
          : data?.switch_third_part_rac_reason,
        rac_amount: !data?.switch_third_part_rac_reason
          ? undefined
          : data?.rac_amount
      })
    },
    onGetResource: {
      setFields: (data) => {
        setIsSwitchRac(data?.switch_third_part_rac);
        setIsSwitchAMC(data?.amc);
        setSelectedCenter(data?.center);
        setLinkedPrescribers(data?.prescriber);
        fetchHealthInsuranceIds(data?.prescriber);
        setInitialPrescription(data?.initial_prescription || []);
        setHealthInsurance(data?.health_insurance || []);
        return {
          ...data,
          created_at: data?.created_at && dayjs(data.created_at),
          prescription_date_1:
            data?.prescription_date_1 && dayjs(data.prescription_date_1),
          prescription_date_2:
            data?.prescription_date_2 && dayjs(data.prescription_date_2),
          rac_start_date: data?.rac_start_date && dayjs(data.rac_start_date),
          date_of_birth: data?.date_of_birth && dayjs(data.date_of_birth),
          rac_end_date: data?.rac_end_date && dayjs(data.rac_end_date),
          registered_invoicing_date:
            data?.registered_invoicing_date &&
            dayjs(data.registered_invoicing_date),
          last_communication_date:
            data?.last_communication_date &&
            dayjs(data.last_communication_date),
          invoicing_start_date:
            data?.invoicing_start_date && dayjs(data.invoicing_start_date),
          invoicing_end_date:
            data?.invoicing_end_date && dayjs(data.invoicing_end_date),
          invoicing_forecast_date:
            data?.invoicing_forecast_date &&
            dayjs(data.invoicing_forecast_date),
          switch_third_part_rac_date:
            data?.switch_third_part_rac_date &&
            dayjs(data.switch_third_part_rac_date),
          reminders_prescription: (data?.reminders_prescription || []).map(
            (reminder) => ({
              ...reminder,
              date: reminder?.date && dayjs(reminder?.date)
            })
          ),
          reminders_rac: (data?.reminders_rac || []).map((reminder) => ({
            ...reminder,
            date: reminder?.date && dayjs(reminder?.date)
          })),
          reminders_insurance_card: (data?.reminders_insurance_card || []).map(
            (reminder) => ({
              ...reminder,
              date: reminder?.date && dayjs(reminder?.date)
            })
          )
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...formValues,
        ...data,
        switch_third_part_rac_date: !data?.switch_third_part_rac
          ? undefined
          : data?.switch_third_part_rac_date,
        switch_third_part_rac_reason: !data?.switch_third_part_rac
          ? undefined
          : data?.switch_third_part_rac_reason,
        rac_amount: !data?.switch_third_part_rac_reason
          ? undefined
          : data?.rac_amount
      })
    }
  };

  const tabs = [
    {
      label: t('patients.form.titles.patient'),
      key: '1',
      children: patientFields.map((field) => generateFields('patients', field))
    },
    {
      label: t('patients.form.titles.caregiver'),
      key: '2',
      children: caregiverFields.map((field) =>
        generateFields('patients', field)
      )
    },
    {
      label: t('patients.form.titles.center'),
      key: '3',
      children: centerFields.map((field) => generateFields('patients', field))
    },
    {
      label: t('patients.form.titles.invoice'),
      key: '4',
      children: invoicingFields.map((field) =>
        generateFields('patients', field)
      )
    },
    {
      label: t('patients.form.titles.rac'),
      key: '5',
      children: racFields.map((field) => generateFields('patients', field))
    }
  ];

  const errorValidationAction = (errors) => {
    if (errors?.errorFields?.[0]?.name?.[0]) {
      const fieldName = errors?.errorFields?.[0]?.name?.[0];
      const isFieldInPatient = patientFields.some((field) =>
        field.name.includes(fieldName)
      );
      const isFieldInCenter = centerFields.some((field) =>
        field.name.includes(fieldName)
      );
      const isFieldInInvoicing = invoicingFields.some((field) =>
        field.name.includes(fieldName)
      );
      const isFieldInRac = racFields.some((field) =>
        field.name.includes(fieldName)
      );
      if (isFieldInRac) setActiveKey('5');
      if (isFieldInInvoicing) setActiveKey('4');
      if (isFieldInCenter) setActiveKey('3');
      if (isFieldInPatient) setActiveKey('1');
    }
  };

  useEffect(() => {
    setSelectedCenter(null);
    setLinkedPrescribers(null);
    setInitialPrescription([]);
    setHealthInsurance([]);
    setIsSwitchRac(false);
    setIsSwitchAMC(false);
    setIsDrawerOpen(false);
    if (enums) setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    const fetchPatient = async () => {
      const data = await getPatientById(id);
      if (data && data.status === 'INACTIVE') {
        navigate('/');
      }
    };

    if (purpose === 'edit') {
      fetchPatient();
    }
  }, [id, navigate, getPatientById]);

  return (
    <>
      {isDrawerOpen && (
        <CenterPrecriberDrawer
          isDrawerOpen={isDrawerOpen}
          model={model}
          purpose="create"
          closeDrawer={closeDrawer}
        />
      )}
      <CreateUpdateContainer
        customFormInstance={form}
        tabs={tabs}
        purpose={purpose}
        baseUrl="patients"
        resource="patients"
        populate="initial_prescription,health_insurance"
        config={config}
        loadingFields={isFieldsLoading}
        withFilesManager={false}
        errorValidationAction={errorValidationAction}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        needFormValues
        setFormValues={setFormValues}
        refresh={forceRefresh}
        isFormData={
          (initialPrescription.length > 0 && purpose === 'create') ||
          (healthInsurance.length > 0 && purpose === 'create')
        }
        fileList={initialPrescription}
        fileInsurance={healthInsurance}
      />
    </>
  );
};

CreateUpdatePatient.propTypes = {
  purpose: propTypes.string.isRequired
};
