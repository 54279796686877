export const headers = [
  {
    label: 'code',
    key: 'code'
  },
  {
    label: 'created_at',
    key: 'created_at'
  },
  {
    label: 'registered_invoicing_date',
    key: 'registered_invoicing_date'
  },
  {
    label: 'latitude_number',
    key: 'latitude_number_fake'
  },
  {
    label: 'last_name',
    key: 'last_name'
  },
  {
    label: 'usage_name',
    key: 'usage_name'
  },
  {
    label: 'first_name',
    key: 'first_name'
  },
  {
    label: 'phone_number.country_code',
    key: 'phone_number.country_code'
  },
  {
    label: 'phone_number.number',
    key: 'phone_number.number'
  },
  {
    label: 'email',
    key: 'email'
  },
  {
    label: 'address.number',
    key: 'address.number'
  },
  {
    label: 'address.street',
    key: 'address.street'
  },
  {
    label: 'address.additional',
    key: 'address.additional'
  },
  {
    label: 'address.postal_code',
    key: 'address.postal_code'
  },
  {
    label: 'address.city',
    key: 'address.city'
  },
  {
    label: 'address.country',
    key: 'address.country'
  },
  {
    label: 'social_security_number',
    key: 'social_security_number'
  },
  {
    label: 'date_of_birth',
    key: 'date_of_birth'
  },
  {
    label: 'rank_birth',
    key: 'rank_birth'
  },
  {
    label: 'quality',
    key: 'quality'
  },
  {
    label: 'prescription_date_1',
    key: 'prescription_date_1'
  },
  {
    label: 'prescription_date_2',
    key: 'prescription_date_2'
  },
  {
    label: 'prescriber.rpps_number',
    key: 'prescriber.rpps_number'
  },
  {
    label: 'medical_device_reference',
    key: 'medical_device_reference'
  },
  {
    label: 'medical_device_serial_number',
    key: 'medical_device_serial_number'
  },
  {
    label: 'center.soldto',
    key: 'center.soldto'
  },
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'initial_prescription',
    key: 'initial_prescription'
  },
  {
    label: 'ald_prescription',
    key: 'ald_prescription'
  },
  {
    label: 'center.sales_representative',
    key: 'center.sales_representative'
  },
  {
    label: 'center.regional_director',
    key: 'center.regional_director'
  },
  {
    label: 'center.center_name',
    key: 'center.center_name'
  },
  {
    label: 'center.finess_number',
    key: 'center.finess_number'
  },
  {
    label: 'last_name_prescriber',
    key: 'prescriber.last_name'
  },
  {
    label: 'first_name_prescriber',
    key: 'prescriber.first_name'
  },
  {
    label: 'prescriber.health_insurance_id',
    key: 'prescriber.health_insurance_id'
  },
  {
    label: 'last_communication_date',
    key: 'last_communication_date'
  },
  {
    label: 'invoicing_start_date',
    key: 'invoicing_start_date'
  },
  {
    label: 'invoicing_end_date',
    key: 'invoicing_end_date'
  },
  {
    label: 'invoicing_forecast_date',
    key: 'invoicing_forecast_date'
  },
  {
    label: 'payingagency_amo.name_agency',
    key: 'payingagency_amo.name_agency'
  },
  {
    label: 'transmission_code_amo',
    key: 'payingagency_amo.transmission_code'
  },
  {
    label: 'risk',
    key: 'risk'
  },
  {
    label: 'exemption',
    key: 'exemption'
  },
  {
    label: 'ame',
    key: 'ame'
  },
  {
    label: 'amc',
    key: 'amc'
  },
  {
    label: 'payingagency_amc.name_agency',
    key: 'payingagency_amc.name_agency'
  },
  {
    label: 'payingagency_amc.transmission_code',
    key: 'payingagency_amc.transmission_code'
  },
  {
    label: 'switch_third_part_rac_date',
    key: 'switch_third_part_rac_date'
  },
  {
    label: 'switch_third_part_rac_reason',
    key: 'switch_third_part_rac_reason'
  },
  {
    label: 'rac_amount',
    key: 'rac_amount'
  },
  {
    label: 'lpp_amount',
    key: 'lpp_amount'
  },
  {
    label: 'lpp_code',
    key: 'lpp_code'
  }
];
