import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const PatientContext = createContext({});

/**
 * A context provider component for managing patient-related data and actions.
 *
 * @context
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - The child components to be wrapped by the context provider
 * @returns {ReactNode} The rendered component
 */
export const PatientContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [centers, setCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [linkedPrescribers, setLinkedPrescribers] = useState([]);
  const [isSwitchRAC, setIsSwitchRac] = useState(false);
  const [isSwitchAMC, setIsSwitchAMC] = useState(false);
  const [initialPrescription, setInitialPrescription] = useState([]);
  const [healthInsurance, setHealthInsurance] = useState([]);
  const [prescribers, setPrescribers] = useState([]);
  const [payingAgencies, setPayingAgencies] = useState([]);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [model, setModel] = useState('');
  const [selectedPrescriber, setSelectedPrescriber] = useState(null);
  const [healthInsuranceIds, setHealthInsuranceIds] = useState([]);

  const openDrawer = (modelType) => {
    setModel(modelType);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setModel('');
    setIsDrawerOpen(false);
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/patients/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  const getPatientById = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/patients/${id}` });
      return data;
    } catch (e) {
      message(e);
      return null;
    }
  };

  const getCenters = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/centers?populate=prescribers'
      });
      setCenters(data);
    } catch (e) {
      message(e);
    }
  };

  const getPrescribers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/prescribers' });

      setPrescribers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchHealthInsuranceIds = async (prescriberId) => {
    const { data } = await dispatchAPI('GET', {
      url: `/prescribers/${prescriberId}`
    });
    setHealthInsuranceIds(data?.health_insurance_id);
  };

  const getPayingAgencies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/payingagency'
      });
      setPayingAgencies(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(async () => {
    await Promise.all([
      getEnums(),
      getCenters(),
      getPrescribers(),
      getPayingAgencies()
    ]);
  }, []);

  useEffect(() => {
    if (selectedCenter && centers?.length) {
      setSelectedPrescriber(null);
      const centerFound = centers.find(
        (center) => center._id === selectedCenter
      );

      if (centerFound) setLinkedPrescribers(centerFound.prescribers);
    }
  }, [selectedCenter, formValues, centers]);

  const uploadOrDeleteFile = async (file, id, type, key) => {
    setIsFileLoading(true);
    const formData = new FormData();
    if (type !== 'delete') formData.append(`${key}`, file);
    formData.append('values', JSON.stringify({ ...formValues }));
    try {
      await dispatchAPI('PATCH', {
        url: `/patients/file/${id}?type=${type}&key=${key}&fileId=${file._id}`,
        body: formData
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
    setIsFileLoading(false);
  };

  return (
    <PatientContext.Provider
      value={{
        enums,
        centers,
        setSelectedCenter,
        linkedPrescribers,
        isSwitchRAC,
        isSwitchAMC,
        setIsSwitchRac,
        setIsSwitchAMC,
        uploadOrDeleteFile,
        initialPrescription,
        healthInsurance,
        setInitialPrescription,
        setHealthInsurance,
        isFileLoading,
        selectedCenter,
        setFormValues,
        formValues,
        setLinkedPrescribers,
        forceRefresh,
        isDrawerOpen,
        model,
        openDrawer,
        setIsDrawerOpen,
        closeDrawer,
        getCenters,
        prescribers,
        payingAgencies,
        getPrescribers,
        fetchHealthInsuranceIds,
        selectedPrescriber,
        setSelectedPrescriber,
        healthInsuranceIds,
        setHealthInsuranceIds,
        getPatientById
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

PatientContext.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(PatientContext);
