import { Select, Radio, Form, DatePicker, Button, Upload, message } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseCircleOutlined,
  UploadOutlined,
  DeleteOutlined,
  EyeOutlined
} from '@ant-design/icons';
import usePatientContext from '../PatientContext';
import { AddIcon } from '../../../utils/constants/customIcons';
import { noLabelLayout } from '../../../utils/constants/formLayout';
import { useDownloadDocument } from '../../../utils/downloadDoc';

const { Option } = Select;

/**
 * useFields is a custom hook that provides an array of field center for patient data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const useCenterFields = (purpose, id) => {
  const { t } = useTranslation();
  const {
    centers,
    setSelectedCenter,
    prescribers,
    enums,
    uploadOrDeleteFile,
    initialPrescription,
    setInitialPrescription,
    isFileLoading,
    openDrawer,
    setSelectedPrescriber,
    fetchHealthInsuranceIds,
    selectedPrescriber,
    healthInsuranceIds,
    setHealthInsuranceIds
  } = usePatientContext();

  const onsearch = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const { downloadDocument } = useDownloadDocument();

  const handlePrescriberChange = async (value) => {
    setSelectedPrescriber(value);

    if (value) {
      await fetchHealthInsuranceIds(value);
    } else {
      setHealthInsuranceIds([]);
    }
  };

  return [
    {
      name: ['center'],
      label: 'center_name',
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          onChange={() => setSelectedPrescriber(null)}
          onSelect={(value) => {
            setSelectedPrescriber(null);
            setSelectedCenter(value);
          }}
          onClear={() => {
            setSelectedCenter(null);
            setSelectedPrescriber(null);
          }}
        >
          {(centers || []).map((center) => (
            <Option key={center._id} value={center._id}>
              {`${center?.center_name} ${center?.soldto} / (${center?.finess_number})`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['new_center'],
      label: 'center_name',
      noLabel: true,
      input: (
        <Form.Item {...noLabelLayout}>
          <Button type="primary" onClick={() => openDrawer('centers')}>
            {t('patients.form.buttons.center')}
            &nbsp;
            <AddIcon />
          </Button>
        </Form.Item>
      )
    },
    {
      name: ['prescriber'],
      label: 'prescribers',
      input: (
        <Select
          allowClear
          showSearch
          value={selectedPrescriber}
          onChange={handlePrescriberChange}
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(prescribers || []).map((prescriber, index) => (
            <Option key={prescriber._id || index} value={prescriber._id}>
              {`${prescriber?.last_name || ''} ${
                prescriber?.first_name || ''
              } ${
                prescriber?.rpps_number ? `(${prescriber?.rpps_number})` : ''
              } ${
                prescriber?.health_insurance_id
                  ? `/ ID AM: ${prescriber?.health_insurance_id}`
                  : ''
              }`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['linked_health_insurance_id'],
      label: 'linked_health_insurance_id',
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(healthInsuranceIds || []).map((value) => (
            <Option key={value} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['new_prescriber'],
      noLabel: true,
      input: (
        <Form.Item {...noLabelLayout}>
          <Button type="primary" onClick={() => openDrawer('prescribers')}>
            {t('patients.form.buttons.prescriber')}
            &nbsp;
            <AddIcon />
          </Button>
        </Form.Item>
      )
    },
    {
      name: ['initial_prescription'],
      input: (
        <Upload
          maxCount={1}
          beforeUpload={(file) => {
            if (initialPrescription.length >= 1) {
              message.warning("Vous ne pouvez télécharger qu'un seul fichier.");
              return false;
            }
            if (purpose !== 'edit') {
              const newFile = [...initialPrescription];
              newFile.push(file);
              setInitialPrescription(newFile);
            } else {
              uploadOrDeleteFile(file, id, 'upload', 'initial_prescription');
            }
            return false;
          }}
          onRemove={(doc) => {
            if (purpose !== 'edit') {
              const index = initialPrescription.indexOf(doc);
              const newFile = initialPrescription.slice();
              newFile.splice(index, 1);
              setInitialPrescription(newFile);
            } else {
              const fileIndex = initialPrescription.findIndex(
                (file) => file.metadata?.originalName === doc.name
              );
              if (fileIndex !== -1) {
                uploadOrDeleteFile(
                  initialPrescription[fileIndex],
                  id,
                  'delete',
                  'initial_prescription'
                );
              }
            }
          }}
          fileList={
            isFileLoading
              ? []
              : (initialPrescription || []).map((file, index) => ({
                  uid: index,
                  name: file?.metadata?.originalName || file?.name,
                  status: 'done',
                  url: file.url
                }))
          }
          showUploadList={{
            showDownloadIcon: true,
            downloadIcon: <EyeOutlined onClick={(e) => e.stopPropagation()} />,
            showRemoveIcon: true,
            removeIcon: <DeleteOutlined />
          }}
          onDownload={async (file) => {
            const fileToDownload = initialPrescription.find(
              (f) =>
                f.metadata?.originalName === file.name || f.name === file.name
            );
            if (fileToDownload) {
              if (
                fileToDownload instanceof File ||
                fileToDownload instanceof Blob
              ) {
                const url = URL.createObjectURL(fileToDownload);
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.click();
                URL.revokeObjectURL(url);
              } else if (fileToDownload._id) {
                await downloadDocument(fileToDownload);
              } else {
                message.error('Impossible de visualiser ce fichier');
              }
            }
          }}
        >
          <Button icon={<UploadOutlined />}>{t('buttons.upload')}</Button>
        </Upload>
      )
    },
    {
      name: ['ald_prescription'],
      initialValue: false,
      rules: [{ required: true }],
      input: (
        <Radio.Group
          options={[true, false].map((b) => ({
            label: t(`switch.${b}`),
            value: b
          }))}
        />
      )
    },
    {
      name: ['prescription_date_1'],
      input: <DatePicker format="DD/MM/YYYY" />
    },
    {
      name: ['prescription_date_2'],
      input: <DatePicker format="DD/MM/YYYY" />
    },
    {
      name: ['reminders_prescription'],
      input: (
        <Form.List name="reminders_prescription" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(
                (field, index) =>
                  index < 3 && (
                    <div key={field.name}>
                      <Form.Item
                        {...field}
                        key={[field.name, 'type']}
                        name={[field.name, 'type']}
                        label={t('patients.form.reminder_prescription.type')}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            onsearch(input, option)
                          }
                        >
                          {(enums.remindersType || []).map((r) => (
                            <Option key={r} value={r}>
                              {t(`patients.enums.${r}`)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <CloseCircleOutlined
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          fontSize: '16px',
                          color: 'red',
                          cursor: 'pointer'
                        }}
                        onClick={() => remove(field.name)}
                      />
                      <Form.Item
                        {...field}
                        key={[field.name, 'date']}
                        name={[field.name, 'date']}
                        label={t('patients.form.reminder_prescription.date')}
                      >
                        <DatePicker format="DD/MM/YYYY" />
                      </Form.Item>
                    </div>
                  )
              )}
              {fields.length < 3 && (
                <Form.Item>
                  <Button type="primary" onClick={() => add()}>
                    {t('patients.form.reminder_prescription.addReminder')}
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      )
    }
  ];
};

export default useCenterFields;
